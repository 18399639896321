import create from "zustand"
import {persist} from "zustand/middleware";

let appStore=(set) => ({
    dopen:true,
    rows:[],
    phonenumber:"",
    camprows:[],
    user:{},
    setUser:(user)=>set((state)=>({user:user})),
    setCamprows:(camprows)=>set((state)=>({camprows:camprows})),
    setRows:(rows) => set((state)=>({rows:rows})),
    updateOpen:(dopen)=> set((state)=>({dopen:dopen})),
    setPhonenumber:(phonenumber)=>set((state)=>({phonenumber:phonenumber}))
});

appStore = persist(appStore, {name:"my_app_store"});
export const useAppStore = create(appStore);

