import React ,{useState,useEffect}from 'react';
import AddReports from './AddReports';
import { Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import {Box} from '@mui/material';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from "@mui/material/Typography";

import Divider from "@mui/material/Divider";
import {getDocs,collection,deleteDoc,doc} from "firebase/firestore";
import { db } from '../../firebaseconfig';

 
function Report(){
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      };
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleOpen = () => setOpen(true);
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const [reports, setReports]=useState([]); 
    
    const reportCollectionRef = collection(db, "reports")
  useEffect(()=>{
    getReports();
  },[])

  const getReports = async ()=>{
    const data = await getDocs(reportCollectionRef);
    setReports(data.docs.map((doc)=>({...doc.data(), id:doc.id})))
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
    return(
        <>
        <Box height={10}/>
        <Button variant="contained" onClick={handleOpen} endIcon={<AddCircleIcon />}>
              Add report
            </Button>
              <Modal
                open={open}
                //onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
              <Box sx={style}>
                <AddReports closeEvent={handleClose}/>
                </Box>
              </Modal>
              <Box height={20} />
              {reports.length > 0 && (
                    <Paper sx={{ width: "98%", overflow: "hidden", padding: "12px",bgcolor:"" }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ padding: "10px" ,color:"#212121",fontWeight:"bold"}}
                      >
                      RECENT REPORTS
                    </Typography>
                  <Divider />
                  <Box height={10} />
                  <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead >
                    <TableRow sx={{bgcolor:"#eeeeee"}}>
                  <TableCell align="left" style={{ minWidth: "100px",fontSize: "17px",color:"#002984",fontWeight:"bold" }}>
                    Phone Number
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px",fontSize: "17px",color:"#002984",fontWeight:"bold" }}>
                    Campaign Name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reports
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((report) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={report.code}
                      >
                        <TableCell align="left" sx={{color:"#212121",fontWeight:"600"}}>{report.userid}</TableCell> 
                        <TableCell align="left" sx={{color:"#212121",fontWeight:"600"}}>{report.campaignname}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={reports.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onReportsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
        </>
    )
}

export default Report;