import React,{useEffect, useState} from 'react';
import {  IconButton } from '@mui/material';
import {Typography, Box} from '@mui/material';
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';


function CampaignDetials({fid,closeEvent}){
    const [name, setName] = useState("");
    const [description, setDescription]=useState("");
    const [noofdays, setNoofdays]=useState("");
    const [maxmemberslimit, setMaxmemberslimit]=useState("");
    const [phnno, setPhnno]=useState("");
    const [email, setEmail]=useState("");
    const [location, setLocation]=useState("");
    const [city, setCity]=useState("");
    const [doctorname, setDoctorname]=useState("");
    const [designation, setDesignation]=useState("");
    const [date, setDate]=useState("");
    const [address,setaddress]=useState("")
    const [timefrom, setTimeFrom]=useState("");
    const [timeto, setTimeTo]=useState("");
    const [agegroups, setAgeGroups]=useState("")
  
    useEffect(()=>{
        setName(fid.name);
        setEmail(fid.email);
        setPhnno(fid.phoneno);
        setDescription(fid.description);
        setNoofdays(fid.noofdays);
        setMaxmemberslimit(fid.maxmemberslimit);
        setLocation(fid.location);
        setCity(fid.city);
        setDoctorname(fid.doctorname);
        setDesignation(fid.designation);
        setDate(fid.date);
        setaddress(fid.address);
        setAgeGroups(fid.agegroups)
        setTimeTo(fid.timeto);
        setTimeFrom(fid.timefrom)
    })

    return(
        <>
            <Box />
            <Typography variant="h5" align="center" sx={{fontWeight:"bold",color:"#002984"}}>
                CAMPAIGN DETAILS
            </Typography>
            <IconButton
                style={{position:"absolute", top:"0", right:"0"}}
                onClick={closeEvent}
                >
                    <CloseIcon/>
                </IconButton>
                <Box height={10}/>
           
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <TextField id="standard-basic" 
                    label=" Campaign Name" 
                    variant="standard" 
                    size="small" 
                    value={name}
                    sx={{minWidth:"100%"}}/> 
                    </Grid>

                    {/* <Grid item xs={6}> *
                    <TextField id="standard-basic" 
                    variant="standard" 
                    label
                    size="small" 
                    type="file"
                    //value={image}
                    sx={{minWidth:"100%"}}/> 
                    </Grid> */}

                    <Grid item xs={12}>
                    <TextField id="standard-multiline-flexible" 
                    label="Description" 
                    variant="standard" 
                    size="small" 
                    multiline
                    maxRows={2}
                    value={description}
                 
                    sx={{minWidth:"100%"}} />
                    </Grid>

                    <Grid item xs={12}>
                    <TextField id="standard-multiline-flexible" 
                    label="Address" 
                    variant="standard" 
                    size="small" 
                    multiline
                    maxRows={2}
                    value={address}
                   
                    sx={{minWidth:"100%"}} />
                    </Grid>

                    <Grid item xs={6}>
                    <TextField id="standard-basic" 
                    label="No of days" 
                    variant="standard" 
                    size="small" 
                    value={noofdays}
                   
                    sx={{minWidth:"100%"}}/> 
                    </Grid>

                    <Grid item xs={6}>
                    <TextField id="standard-basic" 
                    label="Max members limit" 
                    variant="standard" 
                    size="small" 
                    value={maxmemberslimit}
                  
                    sx={{minWidth:"100%"}}/> 
                    </Grid>
                    
                    <Grid item xs={6}>
                    <TextField id="standard-basic" 
                    label="Phone Number" 
                    type="number"
                    variant="standard" 
                    size="small"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                      }}
                    value={phnno}
              
                    sx={{minWidth:"100%"}}/> 
                    </Grid>

                    <Grid item xs={6}>
                    <TextField id="standard-basic" 
                    label="Date"
                    variant="standard" 
                    size="small"
                    placeholder='dd-mm-yyyy'
                     
                    value={date}
                   
                    sx={{minWidth:"100%"}}/> 
                    </Grid>

                    <Grid item xs={3}>
                    <TextField id="standard-basic" 
                    label="Time"
                    variant="standard" 
                    size="small"
                    type="time" 
                    InputProps={{
                        startAdornment: <InputAdornment position="start">From</InputAdornment>,
                      }}
                    value={timefrom}
                    
                    sx={{minWidth:"100%"}}/> 
                    </Grid>

                    <Grid item xs={3}>
                    <TextField id="standard-basic" 
                    label
                    variant="standard" 
                    size="small"
                    type="time" 
                    InputProps={{
                        startAdornment: <InputAdornment position="start">To</InputAdornment>,
                      }}
                    value={timeto}
                    sx={{minWidth:"100%"}}/> 
                    </Grid>

                    <Grid item xs={6}>
                    <TextField id="standard-basic" 
                    label="Age Groups" 
                    variant="standard" 
                    size="small" 
                    value={agegroups}
                    sx={{minWidth:"100%"}}/> 
                    </Grid>

                    <Grid item xs={12}>
                    <TextField id="standard-basic" 
                    label="Email Id" 
                    variant="standard" 
                    type="email"
                    size="small"
                    value={email} 
                    sx={{minWidth:"100%"}}/> 
                    </Grid>

                    <Grid item xs={3}>
                    <TextField id="standard-basic" 
                    label="Location" 
                    variant="standard" 
                    type="text"
                    size="small"
                    value={location} 
                
                    sx={{minWidth:"100%"}}/> 
                    </Grid>

                    <Grid item xs={3}>
                    <TextField id="standard-basic" 
                    label="City" 
                    variant="standard" 
                    type="text"
                    size="small"
                    value={city} 
                   
                    sx={{minWidth:"100%"}}/> 
                    </Grid>

                    <Grid item xs={3}>
                    <TextField id="standard-basic" 
                    label="Doctor Name" 
                    variant="standard" 
                    size="small"
                    value={doctorname} 
                   
                    sx={{minWidth:"100%"}}/> 
                    </Grid>

                    <Grid item xs={12}>
                    <TextField id="standard-basic" 
                    label="Designation" 
                    variant="standard" 
                    size="small"
                    value={designation } 
                    
                    sx={{minWidth:"100%"}}/> 
                    </Grid>
                <Box sx={{m:2}}/>
                </Grid>
        </>
    )
}

export default CampaignDetials;