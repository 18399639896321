import { useState } from "react";
import { useAppStore } from '../../appStore';
import { Box } from "@mui/system"
import MenuItem from '@mui/material/MenuItem';
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';

import ScrollToTop from "../../components/ScrollToTop";

function HealthCard({values,handleChange,user}){
    const camprows=useAppStore((state)=>state.camprows)
    
    return(
        <div>
            <ScrollToTop/>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <h1>HEALTH AND ACTIVITY CARD</h1>
            <h4>Name : {user.name}</h4>
            <h4>COMPONENTS</h4>
            <Grid container spacing={2}>
                    <Grid item xs={3} sx={{display:"flex"}}>
                    <Box height={10}/>
                    <TextField 
                    id="standard-basic" 
                    multipleitems
                    label="Select Campaign" 
                    select
                    defaultValue={values.campaignname}
                    onChange={handleChange("campaignname")}
                    variant="standard"
                    sx={{minWidth:"100%"}}
                    >
                        {camprows.map((camprow) => (
                        <MenuItem key={camprow.email} value={camprow.name}>
                          {camprow.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    </Grid>
                    <Box height={20}/>
                    <Grid item xs={12} sx={{display:"flex",margin:"0"}}>
                    <h4>Vision</h4>
                    <TextField 
                    label
                    variant="standard"
                    defaultValue={values.vision}
                    onChange={handleChange("vision")}
                    size="small" 
                    //value={}
                    sx={{minWidth:"25%",marginLeft:"5%"}}/> 
                    </Grid>
                    
                    <Grid item xs={12} sx={{display:"flex",margin:"0"}}>
                    <h4>Ears</h4>
                    <TextField 
                    label
                    variant="standard"
                    size="small"
                    defaultValue={values.ears}
                    onChange={handleChange("ears")}
                    //value={}
                    sx={{minWidth:"25%",marginLeft:"5%"}}/> 
                    </Grid>

                    <Grid item xs={12} sx={{display:"flex",margin:"0"}}>
                    <h4>General Body Measurements</h4>
                    <TextField 
                    label
                    variant="standard"
                    defaultValue={values.generalbodymeasurements}
                    onChange={handleChange("generalbodymeasurements")}
                    size="small" 
                    //value={}
                    sx={{minWidth:"25%",marginLeft:"5%"}}/> 
                    </Grid>

                    <Grid item xs={12} sx={{display:"flex",margin:"0"}}>
                    <h4>Circumferences</h4>
                    <TextField 
                    label
                    variant="standard"
                    defaultValue={values.circumferences}
                    onChange={handleChange("circumferences")}
                    size="small" 
                    //value={}
                    sx={{minWidth:"25%",marginLeft:"5%"}}/> 
                    </Grid>

                    <Grid item xs={12} sx={{display:"flex",margin:"0"}}>
                    <h4>Health Status</h4>
                    <TextField 
                    label
                    variant="standard"
                    defaultValue={values.healthstatus}
                    onChange={handleChange("healthstatus")}
                    size="small" 
                    //value={}
                    sx={{minWidth:"25%",marginLeft:"5%"}}/> 
                    </Grid>
                    
                    <Grid item xs={12} sx={{display:"flex",margin:"0"}}>
                    <h4>Posture Evaluation</h4>
                    <TextField 
                    label
                    variant="standard"
                    defaultValue={values.postureevaluation}
                    onChange={handleChange("postureevaluation")}
                    size="small" 
                    //value={}
                    sx={{minWidth:"25%",marginLeft:"5%"}}/> 
                    </Grid>

                    <Grid item xs={12} sx={{display:"flex",margin:"0"}}>
                    <h4>Sporting Activities(HPE)</h4>
                    <TextField 
                    label
                    variant="standard"
                    defaultValue={values.sportingactivities}
                    onChange={handleChange("sportingactivities")}
                    size="small" 
                    //value={}
                    sx={{minWidth:"25%",marginLeft:"5%"}}/> 
                    </Grid>

                    <Grid item xs={12} sx={{display:"flex",margin:"0"}}>
                    <h4>Health Component</h4>
                    <TextField 
                    label
                    variant="standard"
                    defaultValue={values.healthcomponent}
                    onChange={handleChange("healthcomponent")}
                    size="small" 
                    //value={}
                    sx={{minWidth:"25%",marginLeft:"5%"}}/> 
                    </Grid>

                    <Grid item xs={12} sx={{display:"flex",margin:"0"}}>
                    <h4>Skill Component</h4>
                    <TextField 
                    label
                    variant="standard"
                    defaultValue={values.skillcomponent}
                    onChange={handleChange("skillcomponent")}
                    size="small" 
                    //value={}
                    sx={{minWidth:"25%",marginLeft:"5%"}}/> 
                    </Grid>
                </Grid>      
            </Box>
        </div> 
    )
}
export default HealthCard