import Sidebar from "../../components/Sidebar"
import Navbar from "../../components/Navbar"
import { Box } from "@mui/system"
import Container from '@mui/material/Container';
import MultiStepForm from "./MultiStepForm";

function HealthCard1(){
    return(
        <>
        <Box sx={{ bgcolor:'#eeeeee'}}>
        <Navbar/>
        <Box height={30}/>
        <Box sx={{ display: 'flex' }}>
            <Sidebar/>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Box height={30}/>
            <Container>
            <Box sx={{ bgcolor: '#fff' }} style={{padding:"30px"}}>
                <MultiStepForm/>
                </Box>
            </Container>       
            </Box>
        </Box>
        </Box>
        </> 
    )
}
export default HealthCard1