import React from 'react';

import { Box } from "@mui/system"
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ScrollToTop from '../../components/ScrollToTop';


function AddUserReports({values,handleChange,handle1Change,input,user}){

    // const [inputBox, setInputBox] = useState(false);
    // const [typeofuser, setTypeOfUser]=useState("");


    // function onChange(event) {
        // setTypeOfUser(event.target.value)
        // typeUser();
      // }
      // function typeUser(){
        // if(typeofuser === "student"){
            // setInputBox(true)
        // }else{
            // setInputBox(false)
        // }
      // }
      // useEffect(()=>{
        // typeUser()
      // })

      const options=[
        {
        name:"Student",
        value:"student"
      },
        {
        name:"Adult",
        value:"adult"
      }]

     
    return(
        <div>
          <ScrollToTop/>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
             <h1>HEALTH AND ACTIVITY CARD</h1>
             <h3>GENERAL INFORMATION</h3>
             <h4>Name : {user.name}</h4>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <TextField 
                    id="standard-basic" 
                    label="Type of users" 
                    select
                    defaultValue={values.typeofusers}
                    onChange={handle1Change("typeofusers")}
                    variant="standard"
                    sx={{minWidth:"25%"}}
                    >
                        {options.map((option) => (
                        <MenuItem key={option.name} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    </Grid>

                    <Grid item xs={12}>
                    <TextField 
                    id="standard-basic" 
                    label="Aadhar Card no. of Student(optional)" 
                    variant="standard" 
                    size="small" 
                    defaultValue={values.aadharcard}
                    onChange={handleChange("aadharcard")}
                    sx={{minWidth:"50%"}}/> 
                    </Grid>

                    <Grid item xs={12}>
                    <TextField 
                    id="standard-multiline-flexible"
                    label="Name" 
                    variant="standard" 
                    size="small" 
                    defaultValue={values.name}
                    onChange={handleChange("name")}
                    //value={}
                    multiline
                    maxRows={4}
                    sx={{minWidth:"50%"}}/> 
                    </Grid>

                    {input ?<Grid item xs={3}>
                    <TextField id="standard-basic" 
                    label="Admission No" 
                    defaultValue={values.admissionno}
                    variant="standard" 
                    onChange={handleChange("admissionno")}
                    size="small" 
                    //value={}
                    sx={{minWidth:"50%"}}/> 
                    </Grid>: null}

                    {input ? <Grid item xs={3}>
                    <TextField id="standard-basic" 
                    defaultValue={values.classn}
                    label="Class" 
                    onChange={handleChange("classn")}
                    variant="standard" 
                    size="small" 
                    //value={}
                    sx={{minWidth:"50%"}}/> 
                    </Grid> : null}

                    <Grid item xs={3}>
                    <TextField id="standard-basic" 
                    label="Date Of Birth" 
                    defaultValue={values.dob}
                    onChange={handleChange("dob")}
                    variant="standard" 
                    size="small" 
                    //value={}
                    sx={{minWidth:"50%"}}/> 
                    </Grid>

                    <Grid item xs={3}>
                    <TextField id="standard-basic" 
                    label="Blood Group"
                    defaultValue={values.bloodgroup} 
                    onChange={handleChange("bloodgroup")}
                    variant="standard" 
                    size="small" 
                    //value={}
                    sx={{minWidth:"50%"}}/> 
                    </Grid>
                    
                    <Grid item xs={12}>
                    <TextField id="standard-basic" 
                    label="Mother's Name"
                    defaultValue={values.mothername}
                    onChange={handleChange("mothername")} 
                    variant="standard" 
                    size="small" 
                    //value={}
                    sx={{minWidth:"50%"}}/> 
                    </Grid>

                    <Grid item xs={3}>
                    <TextField id="standard-basic" 
                     defaultValue={values.myob}
                     onChange={handleChange("myob")} 
                    label="YOB" 
                    variant="standard" 
                    size="small" 
                    //value={}
                    sx={{minWidth:"50%"}}/> 
                    </Grid>

                    <Grid item xs={3}>
                    <TextField id="standard-basic"
                     defaultValue={values.mweight}
                     onChange={handleChange("mweight")}  
                    label="Weight" 
                    variant="standard" 
                    size="small" 
                    //value={}
                    sx={{minWidth:"50%"}}/> 
                    </Grid>

                    <Grid item xs={3}>
                    <TextField id="standard-basic"
                    defaultValue={values.mheight}  
                    label="Height" 
                    onChange={handleChange("mheight")}
                    variant="standard" 
                    size="small" 
                    //value={}
                    sx={{minWidth:"50%"}}/> 
                    </Grid>

                    <Grid item xs={3}>
                    <TextField id="standard-basic"
                     defaultValue={values.mbloodgroup}  
                    label="Blood Group" 
                    variant="standard"
                    onChange={handleChange("mbloodgroup")} 
                    size="small" 
                    //value={}
                    sx={{minWidth:"50%"}}/> 
                    </Grid>

                    <Grid item xs={12}>
                    <TextField id="standard-basic" 
                    label="Adhaar Card  No" 
                    variant="standard" 
                    defaultValue={values.maadharcard}
                    onChange={handleChange("maadharcard")} 
                    size="small" 
                    //value={}
                    sx={{minWidth:"50%"}}/> 
                    </Grid>

                    <Grid item xs={12}>
                    <TextField id="standard-basic" 
                    label="Father's Name"
                    defaultValue={values.fathername} 
                    onChange={handleChange("fathername")}
                    variant="standard" 
                    size="small" 
                    //value={}
                    sx={{minWidth:"50%"}}/> 
                    </Grid>

                    <Grid item xs={3}>
                    <TextField id="standard-basic" 
                    label="YOB" 
                    defaultValue={values.fyob} 
                    variant="standard" 
                    onChange={handleChange("fyob")}
                    size="small" 
                    //value={}
                    sx={{minWidth:"75%"}}/> 
                    </Grid>

                    <Grid item xs={3}>
                    <TextField id="standard-basic" 
                    label="Weight"
                    defaultValue={values.fweight}  
                    variant="standard" 
                    onChange={handleChange("fweight")}
                    size="small" 
                    //value={}
                    sx={{minWidth:"75%"}}/> 
                    </Grid>

                    <Grid item xs={3}>
                    <TextField id="standard-basic" 
                    label="Height"
                     defaultValue={values.fheight} 
                     onChange={handleChange("fheight")} 
                    variant="standard" 
                    size="small" 
                    //value={}
                    sx={{minWidth:"75%"}}/> 
                    </Grid>

                    <Grid item xs={3}>
                    <TextField id="standard-basic" 
                    label="Blood Group"
                    defaultValue={values.fbloodgroup}
                    onChange={handleChange("fbloodgroup")}  
                    variant="standard" 
                    size="small" 
                    //value={}
                    sx={{minWidth:"75%"}}/> 
                    </Grid>

                    <Grid item xs={12}>
                    <TextField id="standard-basic" 
                    label="Adhaar Card  No"
                    onChange={handleChange("faadharcard")} 
                    variant="standard"
                    defaultValue={values.faadharcard}  
                    size="small" 
                    //value={}
                    sx={{minWidth:"50%"}}/> 
                    </Grid>

                    <Grid item xs={12}>
                    <TextField id="standard-basic" 
                    label="Family Income" 
                    defaultValue={values.familymonthlyincome}
                    onChange={handleChange("familymonthlyincome")} 
                    variant="standard" 
                    size="small" 
                    //value={}
                    sx={{minWidth:"50%"}}/> 
                    </Grid>
                
                    <Grid item xs={12}>
                    <TextField 
                    id="standard-multiline-flexible"
                    label="Address" 
                    variant="standard" 
                    size="small" 
                    onChange={handleChange("address")}
                    defaultValue={values.address}
                    //value={}
                    multiline
                    maxRows={4}
                    sx={{minWidth:"50%"}}/> 
                    </Grid>

                    <Grid item xs={3}>
                    <TextField id="standard-basic" 
                    label="Phone Number" 
                    variant="standard" 
                    type="number"
                    defaultValue={values.phonenumber}
                    onChange={handleChange("phonenumber")}
                    size="small" 
                    //value={}
                    sx={{minWidth:"50%"}}/> 
                    </Grid>
                
                    <Grid item xs={3}>
                    <TextField id="standard-basic" 
                    label="(M)" 
                    type="number"
                    variant="standard" 
                    size="small" 
                    //value={}
                    sx={{minWidth:"50%"}}/> 
                    </Grid>

                    <Grid item xs={3}>
                    <TextField id="standard-basic" 
                    label="CWSN, Specify"
                    onChange={handleChange("cswnspecify")} 
                    variant="standard" 
                    size="small" 
                    defaultValue={values.cswnspecify}
                    sx={{minWidth:"50%"}}/> 
                    </Grid>
                </Grid>
                <Box height={20}/>
         
            </Box>
            
        </div>
    )
}


export default AddUserReports;