import React from 'react';
import Navbar from "../components/Navbar"
import { Box } from "@mui/system"
import Sidebar from "../components/Sidebar"
import { useAppStore } from '../appStore'
import Container from '@mui/material/Container';


function Account(){
    const setUser = useAppStore((state)=>state.setUser);
    const user=useAppStore((state)=>state.user)
    return(
        <>
        <Box sx={{ bgcolor:'#eeeeee',height:'100vh'}}>
            <Navbar/>
            <Box height={30}/>
                <Box sx={{ display: 'flex'}}>  
                    <Sidebar/>  
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Box height={70}/>
                    <h1>Account</h1>
                    <h3>Email : {user.email}</h3>  
                    </Box>  
                </Box>
        </Box>
        </>
    )
}
 export default Account