import {Routes,Route,BrowserRouter} from "react-router-dom"
import Home from "./pages/Home"
import Reports from "./pages/Reports"
import Users from "./pages/Users"
import Campaign from "./pages/Campaign"
import Login from "./components/Login";
import { AuthContextProvider } from './context/AuthContext';
import ProtectedRoute from "./components/ProtectedRoute";
import ForgotPassword from "./components/ForgotPassword";
import HealthCard1 from "./pages/reports/HealthCard1"
import ScrollToTop from "./components/ScrollToTop"

import Account from "./components/Account"

function App() {
  return (
    <div>
    <AuthContextProvider>
    <BrowserRouter>
    <ScrollToTop/>
    <Routes>
      {/* <Route path="/signup" exact element={<Signup/>}></Route> */}
      <Route path="/resetpassword" exact element={<ForgotPassword/>}></Route>
      <Route path="/login" exact element={<Login/>}></Route>
      <Route path='/users'element={<ProtectedRoute><Users /></ProtectedRoute>}/>
      <Route path='/'element={<ProtectedRoute><Home /></ProtectedRoute>}/>
      <Route path='/reports'element={<ProtectedRoute><Reports/></ProtectedRoute>}/>
      <Route path='/campaign'element={<ProtectedRoute><Campaign/></ProtectedRoute>}/>
      <Route path='/adduserreportform'element={<ProtectedRoute><HealthCard1/></ProtectedRoute>}/>
      <Route path='/account' element={<ProtectedRoute><Account/></ProtectedRoute>}/>
      
      </Routes>
      </BrowserRouter>
      </AuthContextProvider>
    </div>
  );
}

export default App;
