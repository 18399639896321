import  React,{useState,useEffect} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddUserReports from './AddUserReport';
import HealthCard from './HealthCard';
import { useAppStore } from '../../appStore';
import { addDoc,collection,getDocs, query, where} from 'firebase/firestore';
import { db } from '../../firebaseconfig';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';


const steps = ['General Information', 'Components '];

export default function MultiStepForm() {
  const phonenumber = useAppStore((state)=>state.phonenumber)
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0);
  const [inputBox, setInputBox] = useState(false);
  const [skipped, setSkipped] = useState(new Set());
  const [username, setUserName]= useState([])
  // /const [userid, setUserId]= useState("");
  //const [reportid, setReportId]=useState("");
 // const [typeofuser, setTypeOfUser]=useState("");

let q;

const userRef = collection(db, "users");
const userInfo = async ()=>{
  q = query(userRef, where("phonenumber", "==", phonenumber));
  const user= await getDocs(q)
  user.forEach((doc)=>{
    const obj=doc.data();
    // setUserPhoneNumber(obj)
    setUserName(obj)
  })
}
//console.log(userid)
//const reportref = db.ref("reports")
// Get the data on a post that has changed
  //reportref.on('child_changed', (snapshot) => {
  //const changedPost = snapshot.val();
  ///console.log('The updated post title is ' + changedPost.id);
//});

 useEffect(()=>{
  userInfo()
  
 })
 
 const onChange=(input)=>(e)=>{
  setGenInfo({...geninfo,[input]:e.target.value})
  typeUser()
}

function typeUser(){
  if(geninfo.typeofusers === "student"){
      setInputBox(true)
  }else{
      setInputBox(false)
  }
}
useEffect(()=>{
  typeUser()
})

  const [geninfo,setGenInfo]=useState({
    typeofusers:"",
    aadharcard:"",
    name:"",
    admissionno:"",
    classn:"",
    dob:"",
    bloodgroup:"",
    mothername:"",
    myob:"",
    mweight:"",
    mheight:"",
    mbloodgroup:"",
    maadharcard:"",
    fathername:"",
    fyob:"",
    fweight:"",
    fheight:"",
    fbloodgroup:"",
    faadharcard:"",
    familymonthlyincome:"",
    address:"",
    phonenumber:"",
    cswnspecify:""
  })
  const [components, setComponents]=useState({
    campaignname:"",

    vision:"",
    ears:"",
    generalbodymeasurements:"",
    circumferences:"",
    healthstatus:"",
    postureevaluation:"",
    sportingactivities:"",
    healthcomponent:"",
    skillcomponent:""
  })
  
  const handleChange=(input)=>(e)=>{
    setGenInfo({...geninfo,[input]:e.target.value})
  }

  const handle1Change=(input)=>(e)=>{
    setComponents({...components,[input]:e.target.value})
  }

  const isStepOptional = (step) => {
     return step === 4;
  };

 const isStepSkipped = (step) => {
     return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const profilesCollectionRef = collection(db,'generalinformation');
  const reportCollectionRef = collection(db, "reports");

    const handleSubmit=async()=>{
      
    await addDoc(reportCollectionRef,{
      campaignname:components.campaignname,
      userid:username.phonenumber,
      vision:components.vision,
    ears:components.ears,
    generalbodymeasurements:components.generalbodymeasurements,
    circumferences:components.circumferences,
    healthstatus:components.healthstatus,
    postureevaluation:components.postureevaluation,
    sportingactivities:components.sportingactivities,
    healthcomponent:components.healthcomponent,
    skillcomponent:components.skillcomponent
    })

    await addDoc(profilesCollectionRef,{
      userid:username.phonenumber,
      typeofusers:geninfo.typeofusers,
      aadharcard:geninfo.aadharcard,
      name:geninfo.name,
      admissionno:geninfo.admissionno,
      classno:geninfo.classn,
      dob:geninfo.dob,
      bloodgroup:geninfo.bloodgroup,
      mothername:geninfo.mothername,
      motheryob: geninfo.myob,
      motherweight:geninfo.mweight,
      motherheight:geninfo.mheight,
      motherbloodgroup:geninfo.mbloodgroup,
      motheraadharcard:geninfo.maadharcard,
      fathername:geninfo.fathername,
      fatheryob:geninfo.fyob,
      fatherweight:geninfo.fweight,
      fatherheight:geninfo.fheight,
      fatherbloodgroup:geninfo.fbloodgroup,
      fatheraadharcard:geninfo.faadharcard,
      familymonthlyincome:geninfo.familymonthlyincome,
      address:geninfo.address,
      phonenumber:geninfo.phonenumber,
      cswnspecify :geninfo.cswnspecify,
  })
    Swal.fire("Submitted", "Your file has been submitted.", "success")
    navigate("/reports")
    }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleSkip = () => {
    // if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      // throw new Error("You can't skip a step that isn't optional.");
    // }

    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //setSkipped((prevSkipped) => {
      // const newSkipped = new Set(prevSkipped.values());
      // newSkipped.add(activeStep);
      // return newSkipped;
    // });
  // };

  // const handleReset = () => {
    // setActiveStep(0);
  // };

  return (
        <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      
        <React.Fragment>
           {activeStep === 0 && <AddUserReports values={geninfo} handleChange={handleChange} handle1Change={onChange} input={inputBox} user={username}/>} 
          {activeStep === 1 && <HealthCard values={components} handleChange={handle1Change}  user={username}/>}
          {/* {activeStep === 2 && <Confirm values1={geninfo} values2={components}/>} */}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            
              {activeStep < steps.length -1 ? <Button onClick={handleNext}>Next</Button>:null}
              {activeStep === steps.length -1 ? <Button onClick={handleSubmit}>Submit</Button>:null}
            
          </Box>
        </React.Fragment>
    </Box> 
  );
}