import {useState} from "react"
import {  Button, IconButton,Grid } from '@mui/material';
import {Typography, Box} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { useAppStore } from '../../appStore';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Swal from "sweetalert2";
import { db } from '../../firebaseconfig';
import {updateDoc,arrayUnion,doc} from "firebase/firestore"

function AddCampaign({closeEvent,rowid}){
const camprows=useAppStore((state)=>state.camprows)
const [campaignid, setCampaignId]=useState("");

const handleCampaignIdChange = (event)=>{
    setCampaignId(event.target.value);
}
//const userCollectionRef = collection(db, "users");

const handleAddCampaign= async ()=>{
    const addref=doc(db,"users",rowid.rowid)
     await updateDoc(addref, {
        enrolledcampaigns: arrayUnion(campaignid)
    });
        closeEvent();
        Swal.fire("Submitted", "Your file has been submitted.", "success")    
    }

    return(
        <>
        <Box sx={{m:2}}/>
            <Typography variant="h5" align="center" sx={{fontWeight:"bold",color:"#002984",top:"0"}}>
                ADD CAMPAIGN
            </Typography>
            <IconButton
                style={{position:"absolute", top:"0", right:"0"}}
                onClick={closeEvent}
                >
                <CloseIcon/>
                </IconButton>
                <Box height={10}/>
                <TextField 
                    id="standard-basic" 
                    multipleitems
                    label="Select Campaign" 
                    select
                    defaultValue=""
                    onChange={handleCampaignIdChange}
                    variant="standard"
                    sx={{minWidth:"100%"}}
                    >
                        {camprows.map((camprow) => (
                        <MenuItem key={camprow.email} value={camprow.id}>
                          {camprow.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Box height={20}/>
                    <Grid item xs={12}>
                        <Typography varient="h5" align="center">
                        <Button variant="contained" onClick={handleAddCampaign}>ADD</Button>
                        </Typography>
                    </Grid>  
                </>
    )
}
export default AddCampaign
